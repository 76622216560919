import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Footer from "./components/common/Footer";
import Page from "./components/common/Page";
import Datenschutz from "./components/legal/DatenschutzPage";
import Impressum from "./components/legal/ImpressumPage";
import MainPage from "./components/pages/MainPage";
import PasswordResetPage from "./components/pages/PasswordResetPage";
import AuthProvider from "./context/auth";
import NavigationProvider from "./context/navigation";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <MainPage />,
        },
        {
            path: "confirm-password-reset/:token",
            element: <PasswordResetPage />,
        },
    ]);

    return (
        <AuthProvider>
            <NavigationProvider>
                <Toaster
                    toastOptions={{
                        duration: 2000,
                        error: {
                            duration: 3000,
                        },
                    }}
                />
                <Page>
                    <RouterProvider router={router} />
                </Page>
                <Footer />
                <Impressum />
                <Datenschutz />
            </NavigationProvider>
        </AuthProvider>
    );
}

export default App;
