type FormProps = {
    children: React.ReactNode;
};

const Form = ({ children }: FormProps) => {
    return (
        <div className="bg-purple-500 rounded-3xl px-6 py-4">{children}</div>
    );
};

export default Form;
