import { Fragment, useContext } from "react";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";
import { getLanguage } from "../../helpers/getLanguage";
import PrimaryButton from "../common/PrimaryButton";
import UserItem from "./UserItem";
import LoadingScreen from "../common/LoadingScreen";

export interface IUserPageProps {}

const UserPage: React.FC<IUserPageProps> = () => {
    const { user, logoutUser, loading } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <Fragment>
            <div className="px-3 py-3 bg-purple-50 border rounded-3xl">
                <UserItem
                    label={dict["userNameLabel"]}
                    value={user.username}
                    icon="user"
                />
                <UserItem
                    label={dict["userStudiedSentencesLabel"]}
                    value={user.studiedSentences}
                    icon="vocabs"
                />
                <UserItem
                    label={dict["userCorrectedSentencesLabel"]}
                    value={user.correctedSentences}
                    icon="ranking"
                />
                <UserItem
                    label={dict["nativeLanguageLabel"]}
                    value={getLanguage(user.nativeLanguage).flag}
                    icon="native"
                />
                <UserItem
                    label={dict["studyingLanguageLabel"]}
                    value={getLanguage(user.studyingLanguage).flag}
                    icon="learning"
                />
            </div>
            <div className="text-slate-400 text-sm text-start my-8 ml-2">
                {dict["feedbackEmail"]}
                <span className="text-purple-500 ml-1">hallo@hamkke.de</span>
            </div>

            <PrimaryButton
                text={dict["logoutButton"]}
                onClick={() => logoutUser()}
            />
        </Fragment>
    );
};

export default UserPage;
