import PocketBase from "pocketbase";
import { serverURL } from "../helpers/getServerUrl";
import { LanguageChoice } from "../types/languages";
import {
    SentenceRecord,
    SentenceUpdateFormData,
    SentenceWriteFormData,
} from "../types/sentences";
import {
    LoginData,
    RegisterFormData,
    UpdateProfileFormData,
    UserRecord,
} from "../types/user";
import {
    VocabCreateFormData,
    VocabRecord,
    VocabUpdateFormData,
} from "../types/vocab";

export default class Controller {
    static async loginUser(data: LoginData): Promise<UserRecord> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("users")
            .authWithPassword<UserRecord>(data.email, data.password);
        return result.record;
    }

    static async registerUser(data: RegisterFormData): Promise<UserRecord> {
        const client = new PocketBase(serverURL);
        await client.collection("users").create<UserRecord>(data);
        const result = await client
            .collection("users")
            .authWithPassword<UserRecord>(data.email, data.password);
        return result.record;
    }

    static async logoutUser() {
        const client = new PocketBase(serverURL);
        client.authStore.clear();
    }

    static async refreshUser(): Promise<UserRecord> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("users")
            .authRefresh<UserRecord>();
        return result.record;
    }

    static async getUser(id: string): Promise<UserRecord> {
        const client = new PocketBase(serverURL);
        const result = await client.collection("users").getOne<UserRecord>(id);
        return result;
    }

    static async updateUser(
        user: UserRecord,
        data: UpdateProfileFormData
    ): Promise<UserRecord> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("users")
            .update<UserRecord>(user.id, data);
        return result;
    }

    static async requestPasswordReset(email: string): Promise<boolean> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("users")
            .requestPasswordReset(email);
        return result;
    }

    static async confirmPasswordReset(
        token: string,
        password: string,
        passwordConfirm: string
    ): Promise<boolean> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("users")
            .confirmPasswordReset(token, password, passwordConfirm);
        return result;
    }

    static async getUsers(sort: string, filter: string): Promise<UserRecord[]> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("users")
            .getList<UserRecord>(1, 50, {
                sort: sort,
                filter: filter,
            });
        return result.items;
    }

    static async getVocab(vocabId: string): Promise<VocabRecord> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("vocabs")
            .getOne<VocabRecord>(vocabId);
        return result;
    }

    static async getActiveVocabs(): Promise<VocabRecord[]> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("vocabs")
            .getList<VocabRecord>(1, 100, {
                sort: "lastTimeStudied,created",
                filter: `state != "archived"`,
            });
        return result.items;
    }

    static async getArchivedVocabs(): Promise<VocabRecord[]> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("vocabs")
            .getList<VocabRecord>(1, 1000, {
                sort: "lastTimeStudied,created",
                filter: `state = "archived"`,
            });
        return result.items;
    }

    static async addVocab(vocab: VocabCreateFormData): Promise<VocabRecord> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("vocabs")
            .create<VocabRecord>(vocab);
        return result;
    }

    static async updateVocab(
        vocab: VocabRecord,
        data: VocabUpdateFormData
    ): Promise<void> {
        const client = new PocketBase(serverURL);
        await client.collection("vocabs").update<VocabRecord>(vocab.id, data);
    }

    static async deleteVocab(vocab: VocabRecord): Promise<void> {
        const client = new PocketBase(serverURL);
        await client.collection("vocabs").delete(vocab.id);
    }

    static async getSentence(
        vocab: VocabRecord
    ): Promise<SentenceRecord | null> {
        const client = new PocketBase(serverURL);
        try {
            const result = await client
                .collection("sentences")
                .getFirstListItem<SentenceRecord>(`vocab = "${vocab.id}"`);
            return result;
        } catch {
            return null;
        }
    }

    static async createSentence(data: SentenceWriteFormData): Promise<void> {
        const client = new PocketBase(serverURL);
        await client.collection("sentences").create<SentenceRecord>(data);
    }

    static async deleteSentence(sentence: SentenceRecord): Promise<void> {
        const client = new PocketBase(serverURL);
        await client.collection("sentences").delete(sentence.id);
    }

    static async updateSentence(
        sentence: SentenceRecord,
        data: SentenceUpdateFormData
    ): Promise<void> {
        const client = new PocketBase(serverURL);
        await client
            .collection("sentences")
            .update<SentenceRecord>(sentence.id, data);
    }

    static async getCorrection(
        sentenceLanguage: LanguageChoice
    ): Promise<SentenceRecord[]> {
        const client = new PocketBase(serverURL);
        const result = await client
            .collection("sentences")
            .getList<SentenceRecord>(1, 50, {
                filter: `corrected = "" && sentenceLanguage = "${sentenceLanguage}"`,
            });
        return result.items;
    }
}
