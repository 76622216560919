import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Controller from "../../backend/api";
import { SentenceWriteFormData } from "../../types/sentences";
import { VocabRecord } from "../../types/vocab";
import InputButton from "../common/InputButton";
import Form from "../common/Form";
import AreaInput from "../common/TextAreaInput";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";

export interface IWriteSentenceProps {
    vocab: VocabRecord;
    onWriteSentence: (vocab: VocabRecord) => void;
}

const WriteSentence: React.FC<IWriteSentenceProps> = ({
    vocab,
    onWriteSentence,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SentenceWriteFormData>();

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const dataWithTime: SentenceWriteFormData = {
            ...data,
            vocab: vocab.id,
            sentenceLanguage: vocab.vocabLanguage,
        };
        await Controller.createSentence(dataWithTime);
        await Controller.updateVocab(vocab, { state: "waitingForCorrection" });
        onWriteSentence(vocab);
        setLoading(false);
        toast.success(dict["sentenceSend"]);
    });

    useEffect(() => {
        if (errors.text?.type === "required") {
            toast.error(dict["sentenceMissingToast"]);
        }
    }, [errors, dict]);

    return (
        <Form>
            <form onSubmit={onSubmit}>
                <AreaInput
                    label={dict["sentenceWriteLabel"]}
                    register={register("text", { required: true })}
                />
                <InputButton
                    text={dict["sentenceSendToast"]}
                    loading={loading}
                />
            </form>
        </Form>
    );
};

export default WriteSentence;
