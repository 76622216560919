import { FaArrowLeft, FaInfinity } from "react-icons/fa";

type SubNavbarProps = {
    showClick?: boolean;
    onClick?: (() => Promise<void>) | (() => void);
    showBack?: boolean;
    onBack?: (() => Promise<void>) | (() => void);
};

const SubNavbar = ({
    showClick = false,
    onClick,
    showBack = false,
    onBack,
}: SubNavbarProps) => {
    return (
        <div className="flex justify-between mb-2">
            {showBack ? (
                <button onClick={onBack}>
                    <FaArrowLeft className="text-xl sm:text-2xl text-purple-400 m-3 sm:m-5" />
                </button>
            ) : (
                <div></div>
            )}
            {showClick && (
                <button onClick={onClick}>
                    <FaInfinity className="text-xl sm:text-2xl text-purple-400 m-3 sm:m-5" />
                </button>
            )}
        </div>
    );
};

export default SubNavbar;
