import React, { Fragment, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import TextInput from "../common/TextInput";
import InputButton from "../common/InputButton";
import { AuthContext } from "../../context/auth";
import { RegisterFormData } from "../../types/user";
import Title from "../common/Title";
import Form from "../common/Form";
import { LanguageChoice } from "../../types/languages";
import Dropdown from "../common/Dropdown";
import { getDictionary } from "../../helpers/getDictionary";
import SelectInput from "../common/SelectInput";
import { availableLanguages } from "../../helpers/availableLanguages";
import Landing from "../common/Landing";

export interface IRegisterProps {
    language: LanguageChoice;
    setLanguage: (choice: LanguageChoice) => void;
}

const Register: React.FC<IRegisterProps> = ({ language, setLanguage }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterFormData>();

    const { registerUser } = useContext(AuthContext);
    const dict = getDictionary(language);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const result = await registerUser(data);
        if (result.error) {
            if (result.error.data.data.username) {
                toast.error(dict["usernameTakenToast"]);
            } else if (result.error.data.data.email) {
                toast.error(dict["emailInvalidToast"]);
            } else {
                toast.error(dict["somethingWentWrongToast"]);
            }
        }
        setLoading(false);
    });

    useEffect(() => {
        if (errors.username?.type === "required") {
            toast.error(dict["nameMissingToast"]);
        }
        if (errors.email?.type === "required") {
            toast.error(dict["emailMissingToast"]);
        }
        if (errors.password?.type === "required") {
            toast.error(dict["passwordMissingToast"]);
        }
        if (errors.password?.type === "minLength") {
            toast.error(dict["passwordTooShortToast"]);
        }
        if (errors.passwordConfirm?.type === "required") {
            toast.error(dict["passwordConfirmMissingToast"]);
        }
        if (errors.passwordConfirm?.type === "validate") {
            toast.error(dict["passwordValidateToast"]);
        }
        if (errors.nativeLanguage?.type === "required") {
            toast.error(dict["nativeLanguageMissingToast"]);
        }
        if (errors.studyingLanguage?.type === "required") {
            toast.error(dict["studyingLanguageMissingToast"]);
        }
        if (errors.studyingLanguage?.type === "validate") {
            toast.error(dict["languageValidateToast"]);
        }
    }, [errors, dict]);

    return (
        <Fragment>
            <Landing />
            <div className="flex justify-between items-center">
                <Title text={dict["registrationLabel"]} />
                <Dropdown
                    choices={availableLanguages}
                    currentChoice={language}
                    onSelect={setLanguage}
                />
            </div>
            <Form>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label={dict["userNameRegisterLabel"]}
                        type="text"
                        register={register("username", { required: true })}
                    />
                    <TextInput
                        label={dict["emailLabel"]}
                        type="email"
                        register={register("email", { required: true })}
                    />
                    <TextInput
                        label={dict["passwordLabel"]}
                        type="password"
                        register={register("password", {
                            required: true,
                            minLength: 8,
                        })}
                    />
                    <TextInput
                        label={dict["passwordConfirmLabel"]}
                        type="password"
                        register={register("passwordConfirm", {
                            required: true,

                            validate: (value) =>
                                value === watch("password") ||
                                "Passwords don't match.",
                        })}
                    />
                    <SelectInput
                        label={dict["nativeLanguageLabel"]}
                        choices={availableLanguages}
                        register={register("nativeLanguage", {
                            required: true,
                        })}
                    />
                    <SelectInput
                        label={dict["studyingLanguageLabel"]}
                        choices={availableLanguages.slice().reverse()}
                        register={register("studyingLanguage", {
                            required: true,
                            validate: (value) =>
                                value !== watch("nativeLanguage") ||
                                "Languages match.",
                        })}
                    />
                    <InputButton
                        text={dict["registrationLabel"]}
                        loading={loading}
                    />
                </form>
            </Form>
        </Fragment>
    );
};

export default Register;
