import { ThreeDots } from "react-loader-spinner";

type PrimaryButtonProps = {
    text: string;
    onClick: () => void;
    loading?: boolean;
};

const PrimaryButton = ({
    text,
    onClick,
    loading = false,
}: PrimaryButtonProps) => {
    return (
        <div className="w-full flex justify-center mt-5">
            {!loading ? (
                <div
                    className="px-5 sm:px-8 text-sm sm:text-base h-10 sm:h-12 cursor-pointer flex items-center justify-center 
                rounded-3xl bg-purple-700 hover:bg-purple-400 text-white font-bold"
                    onClick={onClick}
                >
                    {text}
                </div>
            ) : (
                <div className="flex justify-center w-full items-center h-12">
                    <ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#c084fc"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
            )}
        </div>
    );
};

export default PrimaryButton;
