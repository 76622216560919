import { LanguageChoice } from "../types/languages";

export const getLanguage = (
    language: LanguageChoice
): { value: string; flag: string } => {
    switch (language) {
        case "german":
            return { value: "Deutsch", flag: "🇩🇪" };
        case "korean":
            return { value: "한국어", flag: "🇰🇷" };
        default:
            return { value: "한국어", flag: "🇰🇷" };
    }
};
