type PageProps = {
    children: React.ReactNode;
};

const Page = ({ children }: PageProps) => {
    return (
        <div className="md:mx-auto flex justify-center pt-5 sm:pt-10 mb-10">
            <div className="w-11/12 md:w-2/3 lg:w-3/5 xl:w-1/2 2xl:w-2/5 px-4 sm:px-8 shadow-xl rounded-3xl bg-white">
                {children}
                <div className="h-6 sm:h-8"></div>
            </div>
        </div>
    );
};

export default Page;
