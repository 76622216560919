import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Controller from "../../backend/api";
import { getTimeInUTC } from "../../helpers/getTime";
import { SentenceRecord, SentenceUpdateFormData } from "../../types/sentences";
import { VocabRecord } from "../../types/vocab";
import InputButton from "../common/InputButton";
import { AuthContext } from "../../context/auth";
import AreaInput from "../common/TextAreaInput";
import Form from "../common/Form";
import { getDictionary } from "../../helpers/getDictionary";

export interface IWriteCorrectionProps {
    sentence: SentenceRecord;
    vocab: VocabRecord;
    reset: () => void;
}

const WriteCorrection: React.FC<IWriteCorrectionProps> = ({
    sentence,
    vocab,
    reset,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const { register, handleSubmit } = useForm<SentenceUpdateFormData>();

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const dataWithTime: SentenceUpdateFormData = {
            ...data,
            corrected: getTimeInUTC(),
            corrector: user.id,
        };
        await Controller.updateSentence(sentence!, dataWithTime);
        await Controller.updateVocab(vocab, { state: "correctionAvailable" });
        const userRecord = await Controller.getUser(user.id);
        await Controller.updateUser(user, {
            correctedSentences: userRecord.correctedSentences + 1,
        });
        toast.success(dict["writeCorrectionSendToast"]);
        reset();
        setLoading(false);
    });

    return (
        <Form>
            <form onSubmit={onSubmit}>
                <AreaInput
                    label={dict["correctedSentenceLabel"]}
                    register={register("correction")}
                />
                <AreaInput
                    label={dict["commentLabel"]}
                    register={register("comment")}
                />
                <InputButton
                    text={dict["writeCorrectionButton"]}
                    loading={loading}
                />
            </form>
        </Form>
    );
};

export default WriteCorrection;
