import { Fragment, useState } from "react";
import Navbar from "../common/Navbar";
import NavbarItem from "../common/NavbarItem";
import Login from "./Login";
import Register from "./Register";
import { LanguageChoice } from "../../types/languages";
import RequestPasswordResetPage from "./RequestPasswordResetPage";

export interface IAuthPageProps {}

const AuthPage: React.FC<IAuthPageProps> = () => {
    const [subPage, setSubPage] = useState<string>("login");
    const [language, setLanguage] = useState<LanguageChoice>("german");

    return (
        <Fragment>
            <Navbar>
                <div className="h-20 w-1/2 sm:w-1/3 flex justify-around items-center">
                    <NavbarItem
                        icon="login"
                        onClick={() => setSubPage("login")}
                        selected={subPage === "login"}
                    />
                    <NavbarItem
                        icon="register"
                        onClick={() => setSubPage("register")}
                        selected={subPage === "register"}
                    />
                </div>
            </Navbar>
            {subPage === "login" && (
                <Login
                    language={language}
                    setLanguage={setLanguage}
                    setSubPage={setSubPage}
                />
            )}
            {subPage === "register" && (
                <Register language={language} setLanguage={setLanguage} />
            )}
            {subPage === "passwordReset" && (
                <RequestPasswordResetPage
                    language={language}
                    setLanguage={setLanguage}
                />
            )}
        </Fragment>
    );
};

export default AuthPage;
