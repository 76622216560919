import { useState, useEffect, Fragment, useContext } from "react";
import Controller from "../../backend/api";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";
import { SentenceRecord } from "../../types/sentences";
import { VocabRecord } from "../../types/vocab";
import NoItems from "../common/NoItems";
import Correction from "./Correction";
import LoadingScreen from "../common/LoadingScreen";

export interface IVocabPageProps {}

const VocabPage: React.FC<IVocabPageProps> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [sentence, setSentence] = useState<SentenceRecord | null>(null);
    const [sentencFetched, setSentenceFetched] = useState<boolean>(false);
    const [vocab, setVocab] = useState<VocabRecord | null>(null);

    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const reset = () => {
        setSentenceFetched(false);
        setSentence(null);
    };

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const sentences = await Controller.getCorrection(
                user.nativeLanguage
            );
            if (sentences.length > 0) {
                const sentence =
                    sentences[Math.floor(Math.random() * sentences.length)];
                setSentence(sentence);
                const vocab = await Controller.getVocab(sentence.vocab);
                setVocab(vocab);
            }
            setSentenceFetched(true);
            setLoading(false);
        };
        !sentencFetched && fetch();
    }, [user, sentencFetched]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <Fragment>
            {sentence && vocab ? (
                <Correction sentence={sentence} vocab={vocab} reset={reset} />
            ) : (
                <NoItems text={dict["correctionNoItems"]} />
            )}
        </Fragment>
    );
};

export default VocabPage;
