import { Fragment } from "react";
import { VocabRecord } from "../../types/vocab";
import List from "../common/List";
import NoItems from "../common/NoItems";
import VocabListItem from "./VocabListItem";

export interface IVocabListProps {
    vocabs: VocabRecord[];
    selectVocab: (vocab: VocabRecord) => void;
    emptyText: string;
}

const VocabList: React.FC<IVocabListProps> = ({
    vocabs,
    selectVocab,
    emptyText,
}) => {
    const onSelect = (vocab: VocabRecord) => {
        selectVocab(vocab);
        // setPage("sentences");
    };

    return (
        <Fragment>
            {vocabs.length !== 0 ? (
                <List>
                    {vocabs.map((el: VocabRecord) => (
                        <VocabListItem
                            key={el.id}
                            vocab={el}
                            onSelect={onSelect}
                            showIcon
                        />
                    ))}
                </List>
            ) : (
                <NoItems text={emptyText} />
            )}
        </Fragment>
    );
};

export default VocabList;
