import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FaEllipsisV } from "react-icons/fa";
import Controller from "../../backend/api";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";
import { VocabRecord } from "../../types/vocab";

export interface IVocabSettingsProps {
    vocab: VocabRecord;
    onDelete: (vocab: VocabRecord) => void;
}

const VocabSettings: React.FC<IVocabSettingsProps> = ({ vocab, onDelete }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedDelete, setSelectedDelete] = useState<boolean>(false);

    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const onDeleteSelect = () => {
        setSelectedDelete(true);
    };

    const onReset = () => {
        setOpen(!open);
        setSelectedDelete(false);
    };

    const handleDelete = async () => {
        await Controller.deleteVocab(vocab);
        onDelete(vocab);
        toast.success(dict["vocabDeletedToast"]);
    };

    return (
        <div className="relative inline-block text-left">
            <div
                className="w-10 h-10 flex justify-end items-center cursor-pointer"
                onClick={onReset}
            >
                <FaEllipsisV className="text-lg sm:text-xl mr-2 text-slate-400" />
            </div>
            {open && (
                <div className="absolute right-0 mt-2 p-2 origin-top-right border rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    {selectedDelete ? (
                        <div className="flex items-center">
                            <div
                                className="hover:bg-slate-200 p-2 rounded-lg cursor-pointer text-slate-800 mr-2 break-keep"
                                onClick={handleDelete}
                            >
                                {dict["confirmLabel"]}
                            </div>
                            <div
                                className="hover:bg-slate-200 p-2 rounded-lg cursor-pointer text-slate-800 break-keep"
                                onClick={onReset}
                            >
                                {dict["cancelLabel"]}
                            </div>
                        </div>
                    ) : (
                        <div
                            className="hover:bg-slate-200 p-2 rounded-lg cursor-pointer text-red-700 break-keep"
                            onClick={onDeleteSelect}
                        >
                            {dict["deleteLabel"]}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default VocabSettings;
