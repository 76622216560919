import { FaYoutube } from "react-icons/fa";

const Landing = () => {
    return (
        <div className="h-24 flex justify-center items-center flex-col">
            <p className="text-slate-500 text-sm sm:text-lg italic group-hover:text-slate-300 mb-4 text-center">
                Hamkke에서 원어민과 함께 공부해요.
            </p>
            <a
                className="flex items-center bg-purple-400 hover:bg-purple-300 rounded-full px-4 py-1"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/watch?v=_dFNL18a6uw"
            >
                <p className="text-white mr-3 font-bold">{"영상보기"}</p>
                <FaYoutube className="text-xl sm:text-3xl text-white" />
            </a>
        </div>
    );
};

export default Landing;
