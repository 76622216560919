import { useState, useEffect } from "react";
import { FaSpellCheck, FaPencilAlt } from "react-icons/fa";
import Controller from "../../backend/api";
import { UserRecord } from "../../types/user";
import Toggle from "../common/Toggle";
import RankingList from "./List";
import LoadingScreen from "../common/LoadingScreen";

export interface IRankingPageProps {}

const RankingPage: React.FC<IRankingPageProps> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [usersByStudied, setUsersByStudied] = useState<UserRecord[]>([]);
    const [usersByCorrected, setUsersByCorrected] = useState<UserRecord[]>([]);
    const [sortCorrected, setSortCorrected] = useState<boolean>(true);

    const onSortToggle = () => {
        setSortCorrected(!sortCorrected);
    };

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const resultByCorrected = await Controller.getUsers(
                "-correctedSentences",
                `correctedSentences != 0 || studiedSentences != 0`
            );
            setUsersByCorrected(resultByCorrected);
            const resultByStudied = await Controller.getUsers(
                "-studiedSentences",
                `correctedSentences != 0 || studiedSentences != 0`
            );
            setUsersByStudied(resultByStudied);
            setLoading(false);
        };
        fetch();
    }, []);

    if (loading) return <LoadingScreen />;

    return (
        <div className="">
            <div className="flex justify-end mb-5">
                <div className="flex justify-end items-center border rounded-full px-3 py-2">
                    <FaSpellCheck className="text-base sm:text-lg text-slate-400 mr-3" />
                    <Toggle onToggle={onSortToggle} />
                    <FaPencilAlt className="text-base sm:text-lg text-slate-400 ml-3" />
                </div>
            </div>
            <RankingList
                users={sortCorrected ? usersByCorrected : usersByStudied}
            />
        </div>
    );
};

export default RankingPage;
