import { Fragment } from "react";
import { FaArchive, FaClock, FaPencilAlt, FaSpellCheck } from "react-icons/fa";
import { VocabRecord, VocabState } from "../../types/vocab";
import VocabSettings from "./VokabSettings";

export interface IVocabListItemProps {
    vocab: VocabRecord;
    onSelect?: (vocab: VocabRecord) => void;
    showIcon?: boolean;
    showSettings?: boolean;
    onDelete?: (vocab: VocabRecord) => void;
}

const VocabListItem: React.FC<IVocabListItemProps> = ({
    vocab,
    onSelect,
    showIcon = false,
    showSettings = false,
    onDelete,
}) => {
    const getVocabIcon = (state: VocabState) => {
        const iconStyle = "text-lg sm:text-xl mx-2 text-purple-400";
        switch (state) {
            case "waitingForCorrection":
                return <FaClock className={iconStyle} />;
            case "correctionAvailable":
                return <FaSpellCheck className={iconStyle} />;
            case "archived":
                return <FaArchive className={iconStyle} />;
            case "noSentence":
                return <FaPencilAlt className={iconStyle} />;
            default:
                return <FaClock className={iconStyle} />;
        }
    };

    return (
        <div
            className={`w-full flex first:rounded-t-xl last:rounded-b-xl px-4 py-3 justify-between border-t border-x last:border-b bg-white ${
                onSelect && "cursor-pointer hover:bg-purple-200"
            }`}
            onClick={onSelect ? () => onSelect(vocab) : () => {}}
        >
            <div className="w-full flex justify-between items-center">
                <div className="w-4/5 sm:w-5/6">
                    <div className="text-base sm:text-lg text-slate-700 font-bold truncate">
                        {vocab.vocab}
                    </div>
                    <div className="text-sm sm:text-base text-slate-500 truncate">
                        {vocab.translation}
                    </div>
                </div>
                <div className="w-1/5 sm:w-1/6 flex justify-end">
                    {vocab.state !== "archived" && showIcon && (
                        <Fragment>
                            {vocab.state === "noSentence" &&
                                getVocabIcon("noSentence")}
                            {vocab.state === "waitingForCorrection" &&
                                getVocabIcon("waitingForCorrection")}
                            {vocab.state === "correctionAvailable" &&
                                getVocabIcon("correctionAvailable")}
                        </Fragment>
                    )}
                    {showSettings && onDelete && (
                        <VocabSettings vocab={vocab} onDelete={onDelete} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default VocabListItem;
