import { germanDictionary } from "../texts/german";
import { koreanDictionary } from "../texts/korean";
import { LanguageChoice } from "../types/languages";

export const getDictionary = (language: LanguageChoice) => {
    switch (language) {
        case "german":
            return germanDictionary;
        case "korean":
            return koreanDictionary;
        default:
            return koreanDictionary;
    }
};
