import PocketBase, { ClientResponseError } from "pocketbase";
import React, { createContext, ReactNode, useEffect, useState } from "react";
import Controller from "../backend/api";
import { serverURL } from "../helpers/getServerUrl";
import {
    ClientResponse,
    LoginData,
    RegisterFormData,
    UserRecord,
} from "../types/user";

export interface IAuthContext {
    user: UserRecord;
    loading: boolean;
    loggedIn: boolean;
    loginUser: (data: LoginData) => Promise<ClientResponse<UserRecord>>;
    registerUser: (
        data: RegisterFormData
    ) => Promise<ClientResponse<UserRecord>>;
    logoutUser: () => void;
}

export const AuthContext = createContext<IAuthContext>({
    user: {} as UserRecord,
    loading: false,
    loggedIn: false,
    loginUser: async () => {
        return { data: null, error: null };
    },
    registerUser: async () => {
        return { data: null, error: null };
    },
    logoutUser: () => {},
});

interface IAuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<UserRecord>({} as UserRecord);
    const [loading, setLoading] = useState<boolean>(true);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const client = new PocketBase(serverURL);
            setUser(client.authStore.model as UserRecord);
            setLoggedIn(true);
            try {
                await Controller.refreshUser();
                client
                    .collection("users")
                    .subscribe<UserRecord>(
                        client.authStore.model!.id,
                        (sub) => {
                            setUser(sub.record);
                        }
                    );
            } catch (error) {
                Controller.logoutUser();
                setLoggedIn(false);
                setUser({} as UserRecord);
            }
            setLoading(false);
        };
        fetch();
    }, []);

    const loginUser = async (
        data: LoginData
    ): Promise<ClientResponse<UserRecord>> => {
        let result: ClientResponse<UserRecord> = {
            data: null,
            error: null,
        };
        try {
            const user = await Controller.loginUser(data);
            setUser(user);
            result.data = user;
            setLoggedIn(true);
        } catch (error) {
            if (error instanceof ClientResponseError) {
                result.error = error;
            }
        }
        return result;
    };

    const registerUser = async (
        data: RegisterFormData
    ): Promise<ClientResponse<UserRecord>> => {
        let result: ClientResponse<UserRecord> = { data: null, error: null };
        try {
            const user = await Controller.registerUser(data);
            setUser(user);
            result.data = user;
            setLoggedIn(true);
        } catch (error) {
            if (error instanceof ClientResponseError) {
                result.error = error;
            }
        }
        return result;
    };

    const logoutUser = () => {
        setLoading(true);
        Controller.logoutUser();
        setUser({} as UserRecord);
        setLoggedIn(false);
        setLoading(false);
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                loggedIn,
                loading,
                loginUser,
                registerUser,
                logoutUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
