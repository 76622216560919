import { useContext, useState } from "react";
import toast from "react-hot-toast";
import Controller from "../../backend/api";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";
import { getTimeInUTC } from "../../helpers/getTime";
import { SentenceRecord } from "../../types/sentences";
import { VocabRecord } from "../../types/vocab";
import PrimaryButton from "../common/PrimaryButton";

export interface IAcknowledgeCorrectionProps {
    vocab: VocabRecord;
    sentence: SentenceRecord;
    onArchive: (vocab: VocabRecord) => void;
    onRecycle: (vocab: VocabRecord) => void;
}

const AcknowledgeCorrection: React.FC<IAcknowledgeCorrectionProps> = ({
    vocab,
    sentence,
    onArchive,
    onRecycle,
}) => {
    const [recycleLoading, setRecycleLoading] = useState<boolean>(false);
    const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const handleArchive = async () => {
        setArchiveLoading(true);
        await Controller.updateVocab(vocab, {
            state: "archived",
            lastTimeStudied: getTimeInUTC(),
        });
        const userRecord = await Controller.getUser(user.id);
        await Controller.updateUser(user, {
            studiedSentences: userRecord.studiedSentences + 1,
        });
        await Controller.updateSentence(sentence, {
            correctionAcknowledged: true,
        });
        toast.success(dict["acknowledgeArchiveToast"]);
        onArchive(vocab);
        setArchiveLoading(false);
    };

    const handleRecycle = async () => {
        setRecycleLoading(true);
        await Controller.deleteSentence(sentence);
        const userRecord = await Controller.getUser(user.id);
        await Controller.updateUser(user, {
            studiedSentences: userRecord.studiedSentences + 1,
        });
        const newVocab = await Controller.addVocab({
            vocab: vocab.vocab,
            vocabLanguage: vocab.vocabLanguage,
            translation: vocab.translation,
            translationLanguage: vocab.translationLanguage,
            owner: vocab.owner,
            state: "archived",
            lastTimeStudied: getTimeInUTC(),
        });
        await Controller.createSentence({
            text: sentence.text,
            vocab: newVocab.id,
            sentenceLanguage: sentence.sentenceLanguage,
            comment: sentence.comment,
            correctionAcknowledged: true,
            correction: sentence.correction,
            corrected: sentence.corrected,
            corrector: sentence.corrector,
        });
        await Controller.updateVocab(vocab, { state: "noSentence" });
        toast.success(dict["acknowledgeRecycleToast"]);
        onRecycle(vocab);
        setRecycleLoading(false);
    };

    return (
        <div className="flex">
            <PrimaryButton
                text={dict["acknowledgeArchiveButton"]}
                onClick={handleArchive}
                loading={archiveLoading}
            />
            <PrimaryButton
                text={dict["acknowledgeRecycleButton"]}
                onClick={handleRecycle}
                loading={recycleLoading}
            />
        </div>
    );
};

export default AcknowledgeCorrection;
