import { ReactNode } from "react";

type NavbarProps = {
    children: ReactNode;
};

const Navbar = ({ children }: NavbarProps) => {
    return (
        <div className="w-full flex justify-center mt-5 mb-2 sm:mb-5">
            {children}
        </div>
    );
};

export default Navbar;
