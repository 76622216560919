import {
    FaUserCheck,
    FaUserPlus,
    FaSpellCheck,
    FaUserAlt,
    FaTrophy,
    FaPencilAlt,
    FaBook,
    FaComment,
} from "react-icons/fa";

export interface IUserItemProps {
    label: string;
    value: string | number;
    icon: string;
}

const UserItem: React.FC<IUserItemProps> = ({ label, value, icon }) => {
    const getIcon = () => {
        const iconStyles = "text-xl text-purple-400";
        switch (icon) {
            case "login":
                return <FaUserCheck className={iconStyles} />;
            case "register":
                return <FaUserPlus className={iconStyles} />;
            case "vocabs":
                return <FaPencilAlt className={iconStyles} />;
            case "correction":
                return <FaSpellCheck className={iconStyles} />;
            case "user":
                return <FaUserAlt className={iconStyles} />;
            case "ranking":
                return <FaTrophy className={iconStyles} />;
            case "learning":
                return <FaBook className={iconStyles} />;
            case "native":
                return <FaComment className={iconStyles} />;
            default:
                return <FaUserAlt className={iconStyles} />;
        }
    };

    return (
        <div
            className={`w-full h-20 flex first:rounded-t-xl last:rounded-b-xl px-4 py-3 justify-between border-t border-x last:border-b bg-white`}
        >
            <div className="w-full flex justify-between items-center">
                <div className="flex items-center">
                    <div className="">{getIcon()}</div>
                    <div className="text-base sm:text-lg text-slate-500 ml-3">
                        {label}
                    </div>
                </div>
                <div className="flex justify-end">
                    <div className="text-base sm:text-lg text-slate-900 font-bold truncate">
                        {value}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserItem;
