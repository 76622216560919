import { SentenceRecord } from "../../types/sentences";
import { VocabRecord } from "../../types/vocab";
import WriteCorrection from "./WriteCorrection";
import VocabListItem from "../vocabs/VocabListItem";
import TextField from "../common/TextField";
import Card from "../common/LowerCard";

export interface ICorrectionProps {
    sentence: SentenceRecord;
    vocab: VocabRecord;
    reset: () => void;
}

const Correction: React.FC<ICorrectionProps> = ({ sentence, vocab, reset }) => {
    return (
        <div className="">
            <VocabListItem vocab={vocab} showIcon={false} />
            <Card>
                <TextField text={sentence!.text} height="h-32" />
            </Card>
            <WriteCorrection vocab={vocab} sentence={sentence} reset={reset} />
        </div>
    );
};

export default Correction;
