import { useState } from "react";
import { getLanguage } from "../../helpers/getLanguage";
import { LanguageChoice } from "../../types/languages";

type DropdownProps = {
    choices: LanguageChoice[];
    currentChoice: LanguageChoice;
    onSelect: (choice: LanguageChoice) => void;
};

const Dropdown = ({ choices, currentChoice, onSelect }: DropdownProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const onItemSelect = (choice: LanguageChoice) => {
        onSelect(choice);
        setOpen(false);
    };

    return (
        <div className="relative inline-block text-left">
            <button
                type="button"
                className="appearance-none inline-flex w-full justify-center items-center rounded-full bg-white px-3 py-1 text-2xl hover:bg-purple-200 focus:outline-none cursor-pointer"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setOpen(!open)}
            >
                {getLanguage(currentChoice).flag}
            </button>
            {open && (
                <div
                    className="absolute right-0 z-10 mt-2 p-2 origin-top-right border rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                >
                    {choices.map((choice: LanguageChoice) => (
                        <div
                            key={choice}
                            className="text-gray-700 block px-4 py-2 text-xl text-center cursor-pointer hover:bg-purple-100 rounded"
                            onClick={() => onItemSelect(choice)}
                        >
                            {getLanguage(choice).flag}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
