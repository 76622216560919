import {
    FaArchive,
    FaBook,
    FaSpellCheck,
    FaTrophy,
    FaUserAlt,
    FaUserCheck,
    FaUserPlus,
} from "react-icons/fa";
type NavbarItemProps = {
    onClick: () => void;
    selected: boolean;
    icon: string;
};

const NavbarItem = ({ onClick, selected, icon }: NavbarItemProps) => {
    const getIcon = () => {
        const iconStyles = "text-xl sm:text-2xl text-purple-700";
        switch (icon) {
            case "login":
                return <FaUserCheck className={iconStyles} />;
            case "register":
                return <FaUserPlus className={iconStyles} />;
            case "vocabs":
                return <FaBook className={iconStyles} />;
            case "correction":
                return <FaSpellCheck className={iconStyles} />;
            case "user":
                return <FaUserAlt className={iconStyles} />;
            case "ranking":
                return <FaTrophy className={iconStyles} />;
            case "archive":
                return <FaArchive className={iconStyles} />;
            default:
                return <FaUserAlt className={iconStyles} />;
        }
    };

    return (
        <div
            className={`h-12 w-12 sm:w-14 sm:h-14 rounded-full hover:bg-purple-200 flex items-center justify-center 
            cursor-pointer ${selected && "bg-purple-100"}`}
            onClick={onClick}
        >
            {getIcon()}
        </div>
    );
};

export default NavbarItem;
