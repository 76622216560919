import { ThreeDots } from "react-loader-spinner";

type InputButtonProps = {
    text: string;
    loading?: boolean;
};

const InputButton = ({ text, loading = false }: InputButtonProps) => {
    return (
        <div className="w-full flex justify-center mt-5">
            {!loading ? (
                <input
                    className="px-8 text-md h-12 cursor-pointer flex items-center justify-center 
           rounded-3xl bg-purple-700 hover:bg-purple-400 text-white font-bold"
                    type="submit"
                    value={text}
                />
            ) : (
                <div className="flex justify-center w-full items-center h-12">
                    <ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
            )}
        </div>
    );
};

export default InputButton;
