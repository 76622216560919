type TitleProps = {
    text: string;
};

const Title = ({ text }: TitleProps) => {
    return (
        <div className="text-xl sm:text-2xl font-bold text-slate-400 my-5">
            {text}
        </div>
    );
};

export default Title;
