import { FaSpellCheck, FaClock, FaPencilAlt } from "react-icons/fa";
import { TextDictionary } from "../../types/texts";
type LegendProps = {
    dict: TextDictionary;
};
const Legend = ({ dict }: LegendProps) => {
    const iconStyle = " text-base mx-2 text-purple-400";
    return (
        <div className="sm:flex my-5 justify-center items-center">
            <div className="flex my-3 items-center">
                <FaPencilAlt className={iconStyle} />
                <div className="text-slate-400 text-xs sm:text-sm">
                    {dict["legendWriteSentence"]}
                </div>
            </div>
            <div className="flex my-3 items-center">
                <FaClock className={iconStyle} />
                <div className="text-slate-400 text-xs sm:text-sm">
                    {dict["legendWaitingForCorrenction"]}
                </div>
            </div>
            <div className="flex my-3 items-center">
                <FaSpellCheck className={iconStyle} />
                <div className="text-slate-400 text-xs sm:text-sm">
                    {dict["legendCorrectionAvailable"]}
                </div>
            </div>
        </div>
    );
};

export default Legend;
