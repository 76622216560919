import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Controller from "../../backend/api";
import { AuthContext } from "../../context/auth";
import { getLanguage } from "../../helpers/getLanguage";
import {
    VocabCreateFormData,
    VocabRecord,
    VocabState,
} from "../../types/vocab";
import Input from "../common/TextInput";
import InputButton from "../common/InputButton";
import Form from "../common/Form";
import { getDictionary } from "../../helpers/getDictionary";

export interface IAddVocabProps {
    onAddVocab: (vocab: VocabRecord) => void;
}

const AddVocab: React.FC<IAddVocabProps> = ({ onAddVocab }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<VocabCreateFormData>();

    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const newVocab = {
            ...data,
            vocabLanguage: user.studyingLanguage,
            translationLanguage: user.nativeLanguage,
            state: "noSentence" as VocabState,
            owner: user.id,
        };
        const result = await Controller.addVocab(newVocab);
        onAddVocab(result);
        reset();
        setLoading(false);
        toast.success(dict["vocabAddedToast"]);
    });

    useEffect(() => {
        if (errors.vocab?.type === "required") {
            toast.error(dict["vocabMissingToast"]);
        }
        if (errors.translation?.type === "required") {
            toast.error(dict["vocabTranslationMissingToast"]);
        }
    }, [errors, dict]);

    return (
        <Form>
            <div className="transition ease-in-out delay-150"></div>
            <form onSubmit={onSubmit}>
                <Input
                    label={getLanguage(user.studyingLanguage).value}
                    type="text"
                    register={register("vocab", { required: true })}
                />
                <Input
                    label={getLanguage(user.nativeLanguage).value}
                    type="text"
                    register={register("translation", {
                        required: true,
                    })}
                />
                <InputButton text={dict["addVocabButton"]} loading={loading} />
            </form>
        </Form>
    );
};

export default AddVocab;
