import { useEffect, useState } from "react";
import { LanguageChoice } from "../../types/languages";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { availableLanguages } from "../../helpers/availableLanguages";
import { getDictionary } from "../../helpers/getDictionary";
import { RequestPasswordResetData } from "../../types/user";
import Dropdown from "../common/Dropdown";
import InputButton from "../common/InputButton";
import TextInput from "../common/TextInput";
import Title from "../common/Title";
import Form from "../common/Form";
import Controller from "../../backend/api";

export interface IRequestPasswordResetPageProps {
    language: LanguageChoice;
    setLanguage: (choice: LanguageChoice) => void;
}

const RequestPasswordResetPage: React.FC<IRequestPasswordResetPageProps> = ({
    language,
    setLanguage,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RequestPasswordResetData>();

    const dict = getDictionary(language);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        await Controller.requestPasswordReset(data.email);
        toast.success(dict["requestPasswordResetToast"]);
        setLoading(false);
    });

    useEffect(() => {
        if (errors.email?.type === "required") {
            toast.error(dict["emailMissingToast"]);
        }
    }, [errors, dict]);

    return (
        <div className="">
            <div className="flex justify-between items-center">
                <Title text={dict["forgotPasswordLabel"]} />
                <Dropdown
                    choices={availableLanguages}
                    currentChoice={language}
                    onSelect={setLanguage}
                />
            </div>
            <Form>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label={dict["emailLabel"]}
                        type="email"
                        register={register("email", {
                            required: true,
                        })}
                    />
                    <InputButton
                        text={dict["forgotPasswordButton"]}
                        loading={loading}
                    />
                </form>
            </Form>
        </div>
    );
};

export default RequestPasswordResetPage;
