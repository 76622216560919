import { Fragment, useContext } from "react";
import { NavigationContext } from "../../context/navigation";
import logo from "../../images/logo.png";

const Footer = () => {
    const { setLegal } = useContext(NavigationContext);

    return (
        <Fragment>
            <img
                className="w-12 sm:w-16 h-12 sm:h-16 mx-auto mb-5 opacity-30"
                src={logo}
                alt="logo"
            />
            <div className="flex justify-center items-center h-12">
                <div
                    className="text-sm text-slate-400 mx-5 cursor-pointer"
                    onClick={() => setLegal("impressum")}
                >
                    Impressum
                </div>
                <div className="text-sm text-slate-400 mx-5 font-bold">
                    hallo@hamkke.de
                </div>
                <div
                    className="text-sm text-slate-400 mx-5 cursor-pointer"
                    onClick={() => setLegal("datenschutz")}
                >
                    Datenschutz
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
