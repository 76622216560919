import { Fragment } from "react";
import { SentenceRecord } from "../../types/sentences";
import { VocabRecord } from "../../types/vocab";
import LowerCard from "../common/LowerCard";
import TextField from "../common/TextField";

export interface ISentenceProps {
    vocab: VocabRecord;
    sentence: SentenceRecord;
}

const Sentence: React.FC<ISentenceProps> = ({ vocab, sentence }) => {
    return (
        <Fragment>
            <div>{vocab.german}</div>
            <LowerCard>
                <TextField
                    text={sentence.text}
                    height="h-32"
                    icon={sentence.corrected && "sentence"}
                />
                {sentence.correction && sentence.corrected && (
                    <TextField
                        text={sentence.correction}
                        height="h-32"
                        icon="correction"
                    />
                )}
                {!sentence.correction && sentence.corrected && (
                    <TextField
                        text=""
                        height="h-32"
                        icon="correction"
                        inactive
                    />
                )}
                {sentence.comment && (
                    <TextField
                        text={sentence.comment}
                        height="h-32"
                        icon="comment"
                    />
                )}
            </LowerCard>
        </Fragment>
    );
};

export default Sentence;
