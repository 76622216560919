import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import TextInput from "../common/TextInput";
import InputButton from "../common/InputButton";
import { LoginData } from "../../types/user";
import { AuthContext } from "../../context/auth";
import Title from "../common/Title";
import Form from "../common/Form";
import Dropdown from "../common/Dropdown";
import { LanguageChoice } from "../../types/languages";
import { getDictionary } from "../../helpers/getDictionary";
import { availableLanguages } from "../../helpers/availableLanguages";
import Landing from "../common/Landing";

export interface ILoginProps {
    language: LanguageChoice;
    setLanguage: (choice: LanguageChoice) => void;
    setSubPage: (page: string) => void;
}

const Login: React.FC<ILoginProps> = ({
    language,
    setLanguage,
    setSubPage,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginData>();

    const { loginUser } = useContext(AuthContext);
    const dict = getDictionary(language);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const result = await loginUser(data);
        if (result.error) {
            if (result.error.data.message === "Failed to authenticate.") {
                toast.error(dict["failedToAuthenticateToast"]);
            } else {
                toast.error(dict["somethingWentWrongToast"]);
            }
        }
        setLoading(false);
    });

    useEffect(() => {
        if (errors.email?.type === "required") {
            toast.error(dict["emailMissingToast"]);
        }
        if (errors.password?.type === "required") {
            toast.error(dict["passwordMissingToast"]);
        }
    }, [errors, dict]);

    return (
        <div className="">
            <Landing />
            <div className="flex justify-between items-center">
                <Title text={dict["loginLabel"]} />
                <Dropdown
                    choices={availableLanguages}
                    currentChoice={language}
                    onSelect={setLanguage}
                />
            </div>
            <Form>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label={dict["emailLabel"]}
                        type="email"
                        register={register("email", {
                            required: true,
                        })}
                    />
                    <TextInput
                        label={dict["passwordLabel"]}
                        type="password"
                        register={register("password", {
                            required: true,
                        })}
                    />
                    <InputButton text={dict["loginLabel"]} loading={loading} />
                </form>
            </Form>
            <button
                className="text-slate-400 text-sm sm:text-base mt-4 ml-1 hover:underline"
                onClick={() => setSubPage("passwordReset")}
            >
                {dict["passwordResetLinkLabel"]}
            </button>
        </div>
    );
};

export default Login;
