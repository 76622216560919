type NoItemsProps = {
    text: string;
};

const NoItems = ({ text }: NoItemsProps) => {
    return (
        <div className="w-full h-40 flex justify-center items-center my-2 bg-purple-50 rounded-xl border p-5">
            <div className="text-slate-400">{text}</div>
        </div>
    );
};

export default NoItems;
