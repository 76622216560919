import { UseFormRegisterReturn } from "react-hook-form";
import { getLanguage } from "../../helpers/getLanguage";
import { LanguageChoice } from "../../types/languages";

type SelectInputProps = {
    label: string;
    choices: LanguageChoice[];
    register: UseFormRegisterReturn<string>;
};

const SelectInput = ({ label, choices, register }: SelectInputProps) => {
    return (
        <div className="w-full bg-white rounded-full h-14 my-6 flex items-center justify-between pl-5 pr-2">
            <label className="text-sm sm:text-base text-slate-400">
                {label}
            </label>
            <div className="relative inline-block text-left">
                <select
                    className="appearance-none inline-flex w-full justify-center items-center rounded-full bg-white px-3 py-1 text-2xl hover:bg-purple-200 focus:outline-none cursor-pointer"
                    {...register}
                >
                    {choices.map((el: LanguageChoice) => (
                        <option key={el} value={el}>
                            {getLanguage(el).flag}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default SelectInput;
