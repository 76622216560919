import { useState } from "react";

type ToggleProps = {
    onToggle: () => void;
};

const Toggle = ({ onToggle }: ToggleProps) => {
    const [toggled, setToggled] = useState<boolean>(false);

    const onClick = () => {
        setToggled(!toggled);
        onToggle();
    };

    return (
        <label className="inline-flex relative items-center cursor-pointer">
            <input
                type="checkbox"
                className="sr-only peer"
                checked={toggled}
                readOnly
            />
            <div
                onClick={onClick}
                className="w-11 h-6 bg-purple-500 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[3px] after:left-[4px] after:bg-white after:rounded-full after:h-[18px] after:w-[18px] after:transition-all"
            ></div>
        </label>
    );
};

export default Toggle;
