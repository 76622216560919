export const getTimeInUTC = () => {
    const utcTimestamp = new Date().getTime();
    const utcDate = new Date(utcTimestamp);
    const utcDateString =
        utcDate.getUTCFullYear() +
        "-" +
        (utcDate.getUTCMonth() + 1).toString().padStart(2, "0") +
        "-" +
        utcDate.getUTCDate().toString().padStart(2, "0") +
        " " +
        utcDate.getUTCHours().toString().padStart(2, "0") +
        ":" +
        utcDate.getUTCMinutes().toString().padStart(2, "0") +
        ":" +
        utcDate.getUTCSeconds().toString().padStart(2, "0");
    return utcDateString;
};
