import { Fragment, useContext } from "react";
import { AuthContext } from "../../context/auth";
import { NavigationContext } from "../../context/navigation";
import Navbar from "../common/Navbar";
import CorrectionPage from "../correction/CorrectionPage";
import VocabPage from "../vocabs/VocabPage";
import AuthPage from "../auth/AuthPage";
import LoadingScreen from "../common/LoadingScreen";
import UserPage from "../user/UserPage";
import NavbarItem from "../common/NavbarItem";
import RankingPage from "../ranking/RankingPage";
import ArchivePage from "../archive/ArchivePage";

export interface IMainPageProps {}

const MainPage: React.FC<IMainPageProps> = () => {
    const { loggedIn, loading } = useContext(AuthContext);
    const { page, setPage } = useContext(NavigationContext);

    if (loading) return <LoadingScreen />;
    if (!loggedIn) return <AuthPage />;

    return (
        <Fragment>
            <Navbar>
                <div className="h-20 w-full lg:w-2/3 flex justify-between items-center">
                    <NavbarItem
                        icon="vocabs"
                        onClick={() => setPage("vocabs")}
                        selected={page === "vocabs"}
                    />
                    <NavbarItem
                        icon="correction"
                        onClick={() => setPage("correction")}
                        selected={page === "correction"}
                    />
                    <NavbarItem
                        icon="ranking"
                        onClick={() => setPage("ranking")}
                        selected={page === "ranking"}
                    />
                    <NavbarItem
                        icon="archive"
                        onClick={() => setPage("archive")}
                        selected={page === "archive"}
                    />
                    <NavbarItem
                        icon="user"
                        onClick={() => setPage("user")}
                        selected={page === "user"}
                    />
                </div>
            </Navbar>
            {page === "vocabs" && <VocabPage />}
            {page === "user" && <UserPage />}
            {page === "correction" && <CorrectionPage />}
            {page === "ranking" && <RankingPage />}
            {page === "archive" && <ArchivePage />}
        </Fragment>
    );
};

export default MainPage;
