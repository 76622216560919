import { useEffect, useState } from "react";
import { LanguageChoice } from "../../types/languages";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { availableLanguages } from "../../helpers/availableLanguages";
import { getDictionary } from "../../helpers/getDictionary";
import { PasswordResetData } from "../../types/user";
import Dropdown from "../common/Dropdown";
import InputButton from "../common/InputButton";
import TextInput from "../common/TextInput";
import Title from "../common/Title";
import Form from "../common/Form";
import Controller from "../../backend/api";

export interface IPasswordResetPageProps {}

const PasswordResetPage: React.FC<IPasswordResetPageProps> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    let { token } = useParams();
    const navigate = useNavigate();
    const [language, setLanguage] = useState<LanguageChoice>("german");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<PasswordResetData>();

    const dict = getDictionary(language);

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        try {
            if (token) {
                await Controller.confirmPasswordReset(
                    token,
                    data.password,
                    data.passwordConfirm
                );
                toast.success(dict["resetPasswordToast"]);
                navigate("/");
            }
        } catch (error) {
            toast.error(dict["somethingWentWrongToast"]);
            setLoading(false);
        }

        setLoading(false);
    });

    useEffect(() => {
        if (errors.password?.type === "required") {
            toast.error(dict["passwordMissingToast"]);
        }
        if (errors.password?.type === "minLength") {
            toast.error(dict["passwordTooShortToast"]);
        }
        if (errors.passwordConfirm?.type === "required") {
            toast.error(dict["passwordConfirmMissingToast"]);
        }
    }, [errors, dict]);

    return (
        <div className="">
            <div className="flex justify-between items-center">
                <Title text={dict["passwordResetLabel"]} />
                <Dropdown
                    choices={availableLanguages}
                    currentChoice={language}
                    onSelect={setLanguage}
                />
            </div>
            <Form>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label={dict["passwordLabel"]}
                        type="password"
                        register={register("password", {
                            required: true,
                            minLength: 8,
                        })}
                    />
                    <TextInput
                        label={dict["passwordConfirmLabel"]}
                        type="password"
                        register={register("passwordConfirm", {
                            required: true,
                            validate: (value) =>
                                value === watch("password") ||
                                "Passwords don't match.",
                        })}
                    />
                    <InputButton
                        text={dict["passwordResetButton"]}
                        loading={loading}
                    />
                </form>
            </Form>
        </div>
    );
};

export default PasswordResetPage;
