import { TextDictionary } from "../types/texts";

export const germanDictionary: TextDictionary = {
    loginLabel: "Login",
    registrationLabel: "Registrierung",
    forgotPasswordLabel: "Passwort zurücksetzen",
    forgotPasswordButton: "Zurücksetzen",
    passwordResetLabel: "Neues Passwort anfordern",
    passwordResetLinkLabel: "Passwort vergessen?",
    passwordResetButton: "Anfordern",
    emailLabel: "Email",
    passwordLabel: "Passwort",
    passwordConfirmLabel: "Passwort wiederholen",
    logoutButton: "Logout",
    nameMissingToast: "Bitte geben Sie Ihren Namen an",
    emailMissingToast: "Bitte geben Sie eine Emailadresse an.",
    passwordMissingToast: "Bitte geben Sie ein Passwort an.",
    passwordConfirmMissingToast: "Bitte bestätigen Sie das Passwort.",
    nativeLanguageMissingToast: "Bitte geben Sie Ihre Muttersprache an.",
    studyingLanguageMissingToast:
        "Bitte geben Sie die Sprache an, die Sie lernen",
    languageValidateToast:
        "Muttersprache und zu lernende Sprache müssen sich unterscheiden.",
    passwordValidateToast: "Passwörter stimmen nicht überein",
    somethingWentWrongToast: "Etwas ist schief gelaufen.",
    failedToAuthenticateToast: "Email oder Passwort fehlerhaft.",
    usernameTakenToast: "Benutzername bereits vergeben.",
    emailInvalidToast: "Email in Benutzung oder ungültig.",
    passwordTooShortToast:
        "Das Passwort muss mindestens 8 Charaktere lang sein.",
    addVocabButton: "Vokabel hinzufügen",
    vocabMissingToast: "Bitte gib eine Vokabel an.",
    vocabTranslationMissingToast: "Bitte gib eine Übsersetzung an.",
    sentenceSend: "Satz abgeschickt.",
    sentenceSendToast: "Abschicken",
    sentenceMissingToast: "Bitte schreib einen Satz.",
    sentenceWriteLabel: "Schreibe einen Beispielsatz...",
    correctionNoItems: "Es gibt zurzeit keine Sätze zum Korrigieren.",
    acknowledgeRecycleButton: "Erneut lernen",
    acknowledgeArchiveButton: "Archivieren",
    acknowledgeRecycleToast: "Vokabel erneut zum Lernen bereitgestellt.",
    acknowledgeArchiveToast: "Vokabel archiviert.",
    vocabListNoItem:
        "Du hast gerade keine Vokabeln zum Lernen. Füge welche hinzu!",
    archiveListNoItem: "Es sind noch keine Vokabeln im Archiv.",
    writeCorrectionSendToast: "Satz korrigiert. Vielen Dank!",
    writeCorrectionButton: "Korrigieren",
    userStudiedSentencesLabel: "Gelernte Sätze",
    userCorrectedSentencesLabel: "Korrigierte Sätze",
    studyingLanguageLabel: "Lernende Sprache",
    nativeLanguageLabel: "Muttersprache",
    userNameLabel: "Name",
    userNameRegisterLabel: "Name (für andere Personen sichtbar)",
    legendWriteSentence: "Satz schreiben",
    legendWaitingForCorrenction: "Warten auf Korrektur",
    legendCorrectionAvailable: "Korrektur verfügbar",
    feedbackEmail: "Hast du eine Frage? Schreib eine Mail.",
    vocabDeletedToast: "Vokabel gelöscht.",
    deleteLabel: "Löschen",
    confirmLabel: "Bestätigen",
    cancelLabel: "Abbrechen",
    vocabAddedToast: "Vokabel hinzugefügt",
    resetPasswordToast: "Passwort geändert.",
    requestPasswordResetToast: "Email verschickt.",
    correctedSentenceLabel: "Korrigierter Satz",
    commentLabel: "Kommentar / Notiz",
};
