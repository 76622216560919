import { FaComment, FaPencilAlt, FaSpellCheck } from "react-icons/fa";

export interface ITextFieldProps {
    text: string;
    height?: string;
    icon?: string;
    inactive?: boolean;
}

const TextField: React.FC<ITextFieldProps> = ({
    text,
    height,
    icon,
    inactive = false,
}) => {
    const getIcon = (inactive: boolean) => {
        const iconStyle = `text-5xl ${
            inactive ? "text-white" : "text-purple-100"
        } absolute z-0`;
        switch (icon) {
            case "sentence":
                return <FaPencilAlt className={iconStyle} />;
            case "correction":
                return <FaSpellCheck className={iconStyle} />;
            case "comment":
                return <FaComment className={iconStyle} />;
            default:
                return <FaComment className={iconStyle} />;
        }
    };

    return (
        <div className="relative flex justify-center items-center">
            <div
                className={`w-full rounded-3xl px-4 py-3 ${height} mb-4 overflow-y-auto ${
                    inactive ? "bg-purple-200" : "bg-white"
                }`}
            >
                <div className="text-sm sm:text-base text-gray-800 relative z-10">
                    {text}
                </div>
            </div>
            {icon && getIcon(inactive)}
        </div>
    );
};

export default TextField;
