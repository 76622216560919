import { FaMedal, FaPencilAlt, FaSpellCheck } from "react-icons/fa";
import { getLanguage } from "../../helpers/getLanguage";
import { UserRecord } from "../../types/user";
import List from "../common/List";

export interface IRankingListProps {
    users: UserRecord[];
}

const RankingList: React.FC<IRankingListProps> = ({ users }) => {
    return (
        <List>
            {users.map((el: UserRecord, i: number) => (
                <div
                    key={el.id}
                    className={`w-full h-16 flex first:rounded-t-xl last:rounded-b-xl px-4 py-3 justify-between border-t border-x last:border-b bg-white`}
                >
                    <div className="w-full flex justify-between items-center">
                        <div className="flex items-center">
                            {i === 0 && (
                                <FaMedal className="text-amber-400 text-lg mr-2" />
                            )}
                            {i === 1 && (
                                <FaMedal className="text-zinc-400 text-lg mr-2" />
                            )}
                            {i === 2 && (
                                <FaMedal className="text-amber-600 text-lg  mr-2" />
                            )}
                            {i > 2 && (
                                <div className="text-slate-400 text-lg font-bold mr-3 ml-1">
                                    {i + 1}
                                </div>
                            )}
                            <div className="text-sm sm:text-lg text-slate-700 font-bold">
                                {el.username}{" "}
                                {getLanguage(el.nativeLanguage).flag}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center mr-2 sm:mr-3">
                                <FaSpellCheck className="text-base sm:text-lg text-slate-400 mr-2" />
                                <div className="flex text-base sm:text-lg text-slate-700 font-bold">
                                    {el.correctedSentences}
                                </div>
                            </div>
                            <div className="flex items-center">
                                <FaPencilAlt className="text-base sm:text-lg text-slate-400 mr-2" />
                                <div className="flex text-base sm:text-lg text-slate-700 font-bold">
                                    {el.studiedSentences}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </List>
    );
};

export default RankingList;
