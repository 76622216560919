import { Fragment, useContext, useEffect, useState } from "react";
import Controller from "../../backend/api";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";
import { VocabRecord } from "../../types/vocab";
import Legend from "../common/Legend";
import AddVocab from "./AddVocab";
import VocabList from "./VocabList";
import LoadingScreen from "../common/LoadingScreen";
import { SentenceRecord } from "../../types/sentences";
import AcknowledgeCorrection from "../sentences/AcknowledgeCorrection";
import Sentence from "../sentences/Sentence";
import WriteSentence from "../sentences/WriteSentence";
import VocabListItem from "./VocabListItem";
import SubNavbar from "../common/SubNavbar";

export interface IVocabPageProps {}

const VocabPage: React.FC<IVocabPageProps> = () => {
    const { user } = useContext(AuthContext);
    const dict = getDictionary(user.nativeLanguage);

    const [vocabs, setVocabs] = useState<VocabRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedVocab, setSelectedVocab] = useState<VocabRecord | null>(
        null
    );
    const [selectedSentence, setSelectedSentence] =
        useState<SentenceRecord | null>(null);

    const addVocab = (vocab: VocabRecord) => {
        setVocabs([...vocabs, vocab]);
    };

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const result = await Controller.getActiveVocabs();
            setVocabs(result);
            setLoading(false);
        };
        fetch();
    }, []);

    const selectVocab = async (vocab: VocabRecord) => {
        setLoading(true);
        const result = await Controller.getSentence(vocab);
        setSelectedSentence(result);
        setSelectedVocab(vocab);
        setLoading(false);
    };

    const reset = () => {
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    const onWriteSentence = (vocab: VocabRecord) => {
        const newVocabs = vocabs.map((el) => {
            if (el.id === vocab.id) {
                return { ...el, state: "waitingForCorrection" } as VocabRecord;
            }
            return el as VocabRecord;
        });
        setVocabs(newVocabs);
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    const onArchive = (vocab: VocabRecord) => {
        const newVocabs = vocabs.filter((el) => el.id !== vocab.id);
        setVocabs(newVocabs);
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    const onRecycle = (vocab: VocabRecord) => {
        const newVocabs = vocabs.map((el) => {
            if (el.id === vocab.id) {
                return { ...el, state: "noSentence" } as VocabRecord;
            }
            return el as VocabRecord;
        });
        setVocabs(newVocabs);
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    const onVocabDelete = (vocab: VocabRecord) => {
        const newVocabs = vocabs.filter((el) => el.id !== vocab.id);
        setVocabs(newVocabs);
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    const showWritingForm = () => {
        return selectedSentence === null;
    };

    const showAcknowledge = () => {
        return (
            selectedSentence &&
            !selectedSentence.correctionAcknowledged &&
            selectedSentence.corrected
        );
    };

    if (loading) return <LoadingScreen />;

    return (
        <Fragment>
            {selectedVocab && <SubNavbar showBack onBack={reset} />}
            {selectedVocab ? (
                <div className="">
                    {showWritingForm() ? (
                        <Fragment>
                            <div className="mb-4">
                                <VocabListItem
                                    vocab={selectedVocab}
                                    showSettings
                                    onDelete={onVocabDelete}
                                />
                            </div>
                            <WriteSentence
                                vocab={selectedVocab}
                                onWriteSentence={onWriteSentence}
                            />
                        </Fragment>
                    ) : (
                        <VocabListItem
                            vocab={selectedVocab}
                            showSettings
                            onDelete={onVocabDelete}
                        />
                    )}
                    {selectedSentence && (
                        <Sentence
                            vocab={selectedVocab}
                            sentence={selectedSentence}
                        />
                    )}
                    {showAcknowledge() && (
                        <AcknowledgeCorrection
                            vocab={selectedVocab}
                            sentence={selectedSentence!}
                            onArchive={onArchive}
                            onRecycle={onRecycle}
                        />
                    )}
                </div>
            ) : (
                <div className="">
                    <VocabList
                        vocabs={vocabs}
                        selectVocab={selectVocab}
                        emptyText={dict["vocabListNoItem"]}
                    />
                    <Legend dict={dict} />
                    <AddVocab onAddVocab={addVocab} />
                </div>
            )}
        </Fragment>
    );
};

export default VocabPage;
