import React, { createContext, ReactNode, useState } from "react";
import { LegalPage, Page } from "../types/pages";

export interface INavigationContext {
    page: Page;
    setPage: (page: Page) => void;
    legal: LegalPage;
    setLegal: (legal: LegalPage) => void;
}

export const NavigationContext = createContext<INavigationContext>({
    page: "vocabs",
    setPage: () => {},
    legal: "",
    setLegal: () => {},
});

interface INavigationProviderProps {
    children: ReactNode;
}

const NavigationProvider: React.FC<INavigationProviderProps> = ({
    children,
}) => {
    const [page, setPage] = useState<Page>("vocabs");
    const [legal, setLegal] = useState<LegalPage>("");

    return (
        <NavigationContext.Provider value={{ page, setPage, legal, setLegal }}>
            {children}
        </NavigationContext.Provider>
    );
};

export default NavigationProvider;
