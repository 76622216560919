import { Rings } from "react-loader-spinner";

export interface ILoadingScreenProps {}

const LoadingScreen: React.FC<ILoadingScreenProps> = () => {
    return (
        <div className="flex justify-center h-80 items-center">
            <Rings
                height="80"
                width="80"
                color="#a855f7"
                radius="6"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
            />
        </div>
    );
};

export default LoadingScreen;
