import { TextDictionary } from "../types/texts";

export const koreanDictionary: TextDictionary = {
    loginLabel: "로그인",
    registrationLabel: "등록",
    emailLabel: "이메일",
    forgotPasswordLabel: "Passwort zurücksetzen",
    forgotPasswordButton: "Zurücksetzen",
    passwordResetLabel: "Neues Passwort anfordern",
    passwordResetLinkLabel: "Passwort vergessen?",
    passwordResetButton: "Anfordern",
    passwordLabel: "암호",
    passwordConfirmLabel: "암호",
    logoutButton: "로그아웃",
    nameMissingToast: "이름을 입력하세요.",
    emailMissingToast: "이메일 주소를 입력하세요.",
    passwordMissingToast: "암호를 입력하세요.",
    passwordConfirmMissingToast: "암호를 확인하세요.",
    nativeLanguageMissingToast: "모국어를 입력하세요.",
    studyingLanguageMissingToast: "공부하고 있는 언어를 입력하세요.",
    somethingWentWrongToast: "문제가 생겼습니다.",
    failedToAuthenticateToast: "이메일 또는 암호를 잘못 입력하였습니다.",
    usernameTakenToast: "이미 사용중인 이름입니다.",
    emailInvalidToast: "이미 사용중인 이메일 또는 유효하지 않는 이메일 입니다.",
    passwordTooShortToast: "암호는 최소 8자 이상이어야 합니다.",
    languageValidateToast: "공부하는 언어는 모국어와 다르게 등록해 주세요.",
    passwordValidateToast: "암호가 일치하지 않음.",
    addVocabButton: "단어 추가",
    vocabMissingToast: "단어를 입력하세요.",
    vocabTranslationMissingToast: "번역을 지정하십시오.",
    sentenceSend: "문장을 보냈습니다.",
    sentenceSendToast: "보내기",
    sentenceMissingToast: "문장을 입력하세요.",
    sentenceWriteLabel: "예시문을 작성해 주십시오...",
    correctionNoItems: "고칠 문장이 없습니다.",
    acknowledgeRecycleButton: "다시 학습",
    acknowledgeArchiveButton: "저장 하기",
    acknowledgeRecycleToast: "학습용 어휘가 다시 제공됩니다.",
    acknowledgeArchiveToast: "배운 단어를 저장했습니다.",
    vocabListNoItem: "지금 공부할 어휘가 없습니다. 좀 더 추가하세요!",
    archiveListNoItem: "보관함에 아직 단어가 없습니다.",
    writeCorrectionSendToast: "설정 수정. 감사합니다!",
    writeCorrectionButton: "고치기",
    userStudiedSentencesLabel: "학습된 문장",
    userCorrectedSentencesLabel: "고친 문장",
    studyingLanguageLabel: "공부하고 있는 언어",
    nativeLanguageLabel: "모국어",
    userNameLabel: "이름",
    userNameRegisterLabel: "이름 (다른 사용자에게 나타남)",
    legendWriteSentence: "예문 작성하기",
    legendWaitingForCorrenction: "첨삭 대기중",
    legendCorrectionAvailable: "첨삭 완료",
    feedbackEmail: "궁금한게 있으신가요? 이메일로 연락 해주세요.",
    vocabDeletedToast: "단어를 삭제했습니다.",
    deleteLabel: "삭제",
    confirmLabel: "확인",
    cancelLabel: "취소",
    vocabAddedToast: "단어를 추가했습니다.",
    resetPasswordToast: "Passwort geändert.",
    requestPasswordResetToast: "Email verschickt.",
    correctedSentenceLabel: "고친문장",
    commentLabel: "추가 설명 / 메모",
};
