type LowerCardProps = {
    children: React.ReactNode;
};

const LowerCard = ({ children }: LowerCardProps) => {
    return (
        <div className="bg-purple-200 rounded-b-3xl mb-4 px-5 pt-5 pb-2">
            {children}
        </div>
    );
};

export default LowerCard;
