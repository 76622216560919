import { UseFormRegisterReturn } from "react-hook-form";

type InputProps = {
    label: string;
    type: string;
    register: UseFormRegisterReturn<string>;
};

const Input = ({ label, type, register }: InputProps) => {
    return (
        <div className="mb-2">
            <input
                className={`my-2 appearance-none rounded-full w-full py-4 px-5 text-gray-700 text-sm sm:text-base 
          focus:outline-none focus:ring focus:ring-purple-800`}
                type={type}
                {...register}
                placeholder={label}
            />
        </div>
    );
};

export default Input;
