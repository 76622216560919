import { useContext, useEffect, useState } from "react";
import Controller from "../../backend/api";
import { AuthContext } from "../../context/auth";
import { getDictionary } from "../../helpers/getDictionary";
import { VocabRecord } from "../../types/vocab";
import VocabList from "../vocabs/VocabList";
import LoadingScreen from "../common/LoadingScreen";
import Sentence from "../sentences/Sentence";
import { SentenceRecord } from "../../types/sentences";
import VocabListItem from "../vocabs/VocabListItem";
import SubNavbar from "../common/SubNavbar";

export interface IArchivePageProps {}

const ArchivePage: React.FC<IArchivePageProps> = () => {
    const { user } = useContext(AuthContext);
    const [vocabs, setVocabs] = useState<VocabRecord[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const dict = getDictionary(user.nativeLanguage);
    const [selectedVocab, setSelectedVocab] = useState<VocabRecord | null>(
        null
    );
    const [selectedSentence, setSelectedSentence] =
        useState<SentenceRecord | null>(null);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const result = await Controller.getArchivedVocabs();
            setVocabs(result);
            setLoading(false);
        };
        fetch();
    }, []);

    const selectVocab = async (vocab: VocabRecord) => {
        setLoading(true);
        const result = await Controller.getSentence(vocab);
        setSelectedSentence(result);
        setSelectedVocab(vocab);
        setLoading(false);
    };

    const selectRandomVocab = async () => {
        setLoading(true);
        const randomVocab = vocabs[Math.floor(Math.random() * vocabs.length)];
        const result = await Controller.getSentence(randomVocab);
        setSelectedSentence(result);
        setSelectedVocab(randomVocab);
        setLoading(false);
    };

    const goBack = () => {
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    const onVocabDelete = (vocab: VocabRecord) => {
        const newVocabs = vocabs.filter((el) => el.id !== vocab.id);
        setVocabs(newVocabs);
        setSelectedSentence(null);
        setSelectedVocab(null);
    };

    if (loading) return <LoadingScreen />;

    return (
        <div className="">
            {vocabs.length > 0 && (
                <SubNavbar
                    showClick
                    showBack={selectedVocab !== null}
                    onBack={goBack}
                    onClick={selectRandomVocab}
                />
            )}
            {!selectedVocab && (
                <VocabList
                    vocabs={vocabs}
                    selectVocab={selectVocab}
                    emptyText={dict["archiveListNoItem"]}
                />
            )}
            {selectedVocab && selectedSentence && (
                <div>
                    <VocabListItem
                        vocab={selectedVocab}
                        showSettings
                        onDelete={onVocabDelete}
                    />
                    <Sentence
                        vocab={selectedVocab}
                        sentence={selectedSentence}
                    />
                </div>
            )}
        </div>
    );
};

export default ArchivePage;
