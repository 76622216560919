export interface IListProps {
    children: React.ReactNode;
}

const List: React.FC<IListProps> = ({ children }) => {
    return (
        <div className="w-full h-96 overflow-scroll rounded-3xl bg-purple-50 px-3 py-3 border">
            {children}
        </div>
    );
};

export default List;
